<template>
    <div>

        <CButton color="success" square
            size="sm"
            style="float: right"
            @click="openModalEvent('create', 'promotionRuleForm', null, 'Yeni Promosyon Kuralı Ekle' )"
        >
            Kural Ekle
        </CButton>
        <br/>
        <CDataTable
            :items="rules"
            :fields="promotionRuleFields"
            :items-per-page="10"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'promotionRuleForm', lastItem, 'Promosyon Kuralını Düzenle' )"
            v-on:refresh="getRules()"
            class="modalTable"
        >
            <template #show_details="{item, index}">
                <td class="py-2">
                    <CDropdown
                        toggler-text="İşlemler" color="info"
                        class="m-2"
                    >
                        <div role="group">
                            <CDropdownItem @click="openModalEvent('update', 'promotionRuleForm', item, 'Promosyon Kuralını Düzenle' )">Düzenle</CDropdownItem>
                            <CDropdownItem @click="openModalEvent('delete', 'confirm', item, 'Promosyon Kuralını Sil', 'Promosyon kuralını silmek üzeresiniz. Bunu yapmak istediğinizden emin misiniz?' )">Sil</CDropdownItem>
                        </div>
                    </CDropdown>
                </td>
            </template>
            <template #discountOverSalePriceFlag="{item}">
                <td>
                    <CIcon v-if="item.discountOverSalePriceFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                </td>
            </template>
            <template #clearanceIncludedFlag="{item}">
                <td>
                    <CIcon v-if="item.clearanceIncludedFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                </td>
            </template>
            <template #groupNameIncludedFlag="{item}">
                <td>
                    <CIcon v-if="item.groupNameIncludedFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                </td>
            </template>     
            <template #createTime="{item}">
                <td>
                   {{ dateFormat(item.createTime) }}
                </td>
            </template> 
            <template #relatedProductType="{item}">
                <td>
                   {{ item.relatedProductType.name}}
                </td>
            </template> 
            <template #productType="{item}">
                <td>
                   {{ item.productType.name}}
                </td>
            </template> 
                
            <template #promocodeRuleType="{item}">
                <td>
                   {{ item.promocodeRuleType.friendlyType}}
                </td>
            </template>     
        </CDataTable> 
        <ConfirmModal
            v-if="form == 'confirm'" 
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="ruleCrud"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
        />
        <FormModal 
            v-else
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :operationEvent="ruleCrud"
            :actionType="actionType"
            :module="module"
            :form="form"
            :data="modalProps"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import FormModal from '../../components/formModal'
    import ConfirmModal from '../../components/confirmModal'
    export default{
        name: "PromotionRuleList",
        components:{
            FormModal, ConfirmModal
        },
        props:{
            params: Object,
        },
        computed:{
            rules: function() {
                return this.$store.getters.promotionRules
            },
            loading: function() {
                return this.$store.getters.adminLoading
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
                this.getRules()
            },
            modalProps(val){
                if(val && val.relatedProductType)
                    this.modalProps.relatedProductTypeId = val.relatedProductType.id
                if(val && val.productType)
                    this.modalProps.productTypeId = val.productType.id
            }
        },
        data: function(){
            return{
                modalTitle: '',
                actionType: '',
                module: 'admin',
                form: 'promotionForm',
                modalProps: {},
                modalDesc: '',
                openModal: false,
                data: this.params ? {...this.params} : {},
                lastItem: {},
                promotionRuleFields: [
                    {
                    key: 'show_details',
                    label: 'İşlemler',
                    _style: 'font-size:12px'
                    },
                    {key: 'id', label: 'ID', _style: 'font-size:12px'},
                    {key: 'createTime', label: 'Oluşturulma_Tarihi', _style: 'font-size:12px'},
                    {key: 'discountPercent', label: 'İndirim_Yüzdesi', _style: 'font-size:12px'},
                    {key: 'discountAmount', label: 'İndirim_Miktarı', _style: 'font-size:12px'},
                    {key: 'discountOverSalePriceFlag', label: 'Satış_Fiyatı_Üzerinden', _style: 'font-size:12px'},
                    {key: 'minSalePriceTotal', label: 'Min_Satış_Fiyatı', _style: 'font-size:12px'},
                    {key: 'maxCount', label: 'Max_Adet', _style: 'font-size:12px'},
                    {key: 'execOrder', label: 'İşlenme_Sırası', _style: 'font-size:12px'},
                    {key: 'clearanceIncludedFlag', label: 'Clearance_Ürün_Dahil', _style: 'font-size:12px'},
                    {key: 'relatedProductMinCount', label: 'Min_İlişik_Ürün_Adeti', _style: 'font-size:12px'},
                    {key: 'promocodeRuleType', label: 'Kural_Tipi', _style: 'font-size:12px'},
                    {key: 'groupName', label: 'Grup', _style: 'font-size:12px'},
                    {key: 'productType', label: 'Ürün_Tipi', _style: 'font-size:12px'},
                    {key: 'relatedProductType', label: 'İlişik_Ürün_Tipi', _style: 'font-size:12px'},
                    {key: 'groupNameIncludedFlag', label: 'Grup_İsmi_Ekli', _style: 'font-size:12px'}
                ],
            }
        },
        mounted: function(){
            this.getRules()
        },
        methods: {
            openModalEvent(actionType, form, data, title, desc){
                this.openModal = !this.openModal
                this.actionType = actionType
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            getRules: async function(){
                var formData = new FormData();
                formData.append("filter" + "[0][field]", 'promocode.id');
                formData.append("filter" + "[0][data][type]", 'numeric');
                formData.append("filter" + "[0][data][value]", this.data.id);
                formData.append("filter" + "[0][data][comparison]", 'eq');

                await this.$store.dispatch('promotionRules_list', formData)
                await this.$store.dispatch('activeProductType_list')

            },
            dateFormat(date){
                return moment(date).format("DD.MM.YYYY HH:mm:ss")
            },
            rowClicked(item){
                this.lastItem = item
            },
            async ruleCrud(item, action){
                let params = {
                    "actionType" : action,
                    "promoId": this.data.id,
                    "id": item.id,
                    "discountPercent": item.discountPercent,
                    "discountAmount": item.discountAmount,
                    "minSalePriceTotal": item.minSalePriceTotal,
                    "maxCount": item.maxCount,
                    "execOrder": item.execOrder,
                    "relatedProductMinCount": item.relatedProductMinCount,
                    "promocodeRuleType": item.promocodeRuleTypeId,
                    "groupName": item.groupName,
                    "relatedProductTypeId": item.relatedProductTypeId,
                    "discountOverSalePriceFlag": item.discountOverSalePriceFlag,
                    "clearanceIncludedFlag": item.clearanceIncludedFlag,
                    "groupNameIncludedFlag": item.groupNameIncludedFlag,
                    "productTypeId": item.productTypeId
                };
                await this.$store.dispatch('promotionRules_action', params);
                if(this.$store.getters.adminStatus.success){
                    this.getRules()
                    this.openModalEvent()
                }
            }
        }
    }
</script>